




















































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
// import { getView } from '@/utils/helpers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import { getComponent, getView } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import accountModule from '@/store/modules/accountModule';
import workflowModule from '@/store/modules/workflowModule';
import userProfileModule from '@/store/modules/userProfileModule';
import parsePhoneNumber from 'libphonenumber-js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import FuzzySearch from 'fuzzy-search';
import AWS from 'aws-sdk';
import {
  POST_SHAPEFILE_LAMBDA_REGION,
  IDENTITY_POOL_REGION,
  IDENTITY_POOL_ID,
} from '@/lib/constants';
import cellRender from './agGrid/CellActionRender.vue';
// import CellRender from './CellRender.js';
import CustomHeader from './agGrid/header.js';
import EditableHeader from './agGrid/headerEditable.js';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

// import TagSelect from './agGrid/userTagCell.vue';
// import DateSelect from './userDateCell.vue';
// import UserSelect from './userSelectCell.vue';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    AgGridVue,
    cellRender,
    agColumnHeader1: CustomHeader,
    agColumnEdiable: EditableHeader,
    Multiselect,
    VuePhoneNumberInput,
  },
})
export default class Tasqs extends Vue {
   windowWidth = window.innerWidth;

   newColumnName = '';

   searchQuery = '';

   newGroupName = '';

   showModal = false;

  showUserModal =false;

  user: any = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    role: '',
    team: '',
  };

   userDefault: any = {
     firstName: '',
     lastName: '',
     phoneNumber: '',
     email: '',
     role: '',
     team: '',
   };

   errors: any = [];

   validateForm() {
     if (!this.user.firstName) {
       this.errors.push('firstName');
     } else if (!this.user.lastName) {
       this.errors.push('lasttName');
     } else if (!this.user.role) {
       this.errors.push('role');
     } else if (!this.user.email) {
       this.errors.push('email');
     } else if (!this.user.phoneNumber) {
       this.errors.push('phoneNumber');
     } else if (this.user.phoneNumber.charAt(0) != '+') {
       this.errors.push('phoneNumber');
	 }
   }

   async postNewUser() {
     this.dataLoading = true;

     if (this.user.fullPhoneNumber) {
       this.user.phoneNumber = this.user.fullPhoneNumber.formattedNumber;
     }

     this.errors = [];
     this.validateForm();

     if (this.errors && this.errors.length) {
		 this.dataLoading = false;
       return;
     }

     //  const phoneNumber: any = parsePhoneNumber(this.user.phoneNumber);
     //  if (phoneNumber.number) {
     //    this.user.phoneNumber = phoneNumber.number;
     //  }

     if (this.user.id) {
       await this.updateUserDetail();
       await workflowModule.getUserProfile({ username: accountModule.user.email.toLowerCase(), useExisting: false });
     } else {
       await userProfileModule.postNewAdminUser(this.user);
     }
     this.cancelButtonTapped();

     await this.fetchData();
     // this.dataLoading = false;
     // window.location.reload();
   }

   async updateUserDetail() {
     await userProfileModule.updateUserDetails(this.user);
     this.cancelButtonTapped();
     //  window.location.reload();
   }

   cancelButtonTapped() {
     this.showUserModal = false;
     this.user = this.userDefault;
   }

  activeDeleteRowWellName= '';

  diableUserPopup = false;

  hideAgGrid: any = false;

  availableWellNames = [];

  dataLoading = false;

   showColumnMenu = false;

  selectedTags: any = [];

  availableTags = ['Tag1', 'Tag2']

  dropdownLocation: any = []

  getRowNodeId: any = null;

  gridApi: any = null;

  gridColumnApi: any = null;

  showWellMenu = false;

  context: any = null;

  newWellName = '';

  frameworkComponents: any = {};

  selectedWell: any = ''

  columnDefs: any = [

  ];

     defaultColDef = {
       initialWidth: 250,
       sortable: true,
       editable: false,
       autoHeight: true,
       cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
       resizable: true,
     };

    rowData:any = [

    ];

    cellClick(e) {
      // console.log(e);
    }

    get filteredFormData() {
      const searcher = new FuzzySearch(this.rowData, ['name', 'email'], {
	    caseSensitive: false,
	  });
	  return searcher.search(this.searchQuery);
    }

    get isMobile() {
      return this.windowWidth < 769;
    }

    async stopEdit() {
      await this.saveGroupDetailsToBackend();
    }

    closeDialog() {
      this.hideAgGrid = true;
      // console.log('close');
      this.showModal = false;
      setTimeout(() => {
        this.hideAgGrid = false;
      }, 300);
    }

    async onGridReady(params) {
      this.gridApi = params.api;
      this.rowData = [];
      this.columnDefs = [];
      this.gridColumnApi = params.columnApi;
      const columns = [];
      this.gridApi.hideOverlay();

      this.columnDefs = [
        {
          field: 'name',
          cellClass: 'noborder',
          cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
          sortable: true,
          filter: true,
          width: 200,
          pinned: 'left',
          editable: false,
          checkboxSelection: false,
          colId: 'Name',

          headerName: 'Name',
        },
        {
          field: 'email',
          editable: false,
          colId: 'Email',
          cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
          cellClass: 'noborder',
          headerName: 'Email',
        },
        {
          field: 'formatNumber',
          editable: false,
          width: 200,
          colId: 'phoneNumber',
          cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
          cellClass: 'noborder',
          headerName: 'Phone',
        },
        {
          field: 'role',
          editable: false,
          width: 200,
          colId: 'role',
          cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
          cellClass: 'noborder',
          headerName: 'Role',
        },
        {
          field: 'userAdminAccess',
          editable: false,
          width: 200,
          colId: 'userAdminAccess',
          cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
          cellClass: 'noborder',
          headerName: 'Admin Access',
        },
        {
          field: 'team',
          editable: false,
          width: 180,
          colId: 'Team',
          cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
          cellClass: 'noborder',
          headerName: 'Team',
        },

        {
          field: 'active',
          editable: false,
          width: 180,
          colId: 'status',
          cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
          cellClass: 'noborder',
          headerName: 'Status',
        },
        {
          field: 'defaultPage',
          editable: false,
          width: 180,
          colId: 'defaultPage',
          cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
          cellClass: 'noborder',
          headerName: 'Default Page',
        },
        {
          field: 'adminChartAccess',
          editable: false,
          width: 180,
          colId: 'adminChartAccess',
          cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
          cellClass: 'noborder',
          headerName: 'Chart Access',
        },
        {
          field: 'area',
          editable: false,
          width: 520,
          colId: 'Area',
          cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
          cellClass: 'noborder',
          headerName: 'Area',
        },
        {
          field: 'routes',
          editable: false,
          colId: 'routes',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorPopup: true,
          initialWidth: 520,
          cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },

          wrapText: true,
          cellClass: 'noborder',
          headerName: 'Routes',
        },

        {
          field: 'Actions',
          colId: 'actions',
          sortable: true,
          pinned: 'right',
          cellClass: 'noborder',
          suppressToolPanel: true,
          cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
          suppressRowTransform: true,

          editable: false,

          initialWidth: 120,
          cellRendererFramework: 'cellRender',
          cellRendererParams: {
            clicked(field) {
              alert(`${field} was clicked`);
            },
          },
        },
      ];

      await this.fetchData();
    }

    async confirmDisableUser() {
      this.diableUserPopup = false;
      await workflowModule.deleteUser(this.user);
      await userProfileModule.updateAcceptingTasqs({
	    username: this.user.email.toLowerCase(),
	    accepting_tasqs: false,
	  });

      await userProfileModule.updateAcceptingTasqs({
	    username: this.user.email.toLowerCase(),
	    accepting_tasqs: false,
	  });
      // await workflowModule.updateUserActiveStatus(this.user)
      this.user = this.userDefault;
      window.location.reload();
      await this.sleep(5000);
      this.cancelButtonTapped();
      await this.fetchData();
    }

    async cancelDiableUser() {
      this.user = this.userDefault;
      this.diableUserPopup = false;
    }

    async enableUserRecord(data) {
      this.user = data;
      this.diableUserPopup = false;
      // await workflowModule.deleteUser(this.user);
      await workflowModule.updateUserActiveStatus(this.user);
      await userProfileModule.updateAcceptingTasqs({
	    username: this.user.email.toLowerCase(),
	    accepting_tasqs: true,
	  });
      await userProfileModule.updateAcceptingTasqs({
	    username: this.user.email.toLowerCase(),
	    accepting_tasqs: true,
	  });
      this.user = this.userDefault;
      // await this.sleep(5000);
      window.location.reload();
      this.cancelButtonTapped();
      await this.fetchData();
      //  await this.fetchData();
    }

    async saveGroupDetailsToBackend() {
      // const updatedObject = {
      //   group_id: this.activeGroup.ID,
      //   columns: JSON.stringify(this.activeGroup.Columns),
      //   payload: JSON.stringify(this.rowData),
      // };

      // await scheduleModule.updateGroupDetails(updatedObject);
    }

    getDefaultPage(role) {
      // let defaultPage = 'Tasqs';
      // if (role === 'Engineers') {
      //   defaultPage = 'TasqsKanban';
      // } else if (role === 'Mangers') {
      //   defaultPage = 'Report';
      // }
      return 'TasqsKanban';
    }

    beforeMount() {
      this.context = { componentParent: this };
      // @ts-ignore
      window.gridApiVue = this;
    }

    async editUserRecord(data) {
      // this.rowData[index][fieldName] = data;
      this.user = data;
      this.showUserModal = true;
      // console.log( this.rowData[index][fieldName])
    }

    async deleteUserRecord(data) {
      // this.rowData[index][fieldName] = data;
      this.user = data;
      this.diableUserPopup = true;
      // console.log( this.rowData[index][fieldName])
    }

    get teams() {
	   const structuredTeams: any = [];
	   for (let x = 0; x < workflowModule.teams.length; x++) {
		   structuredTeams.push({ text: workflowModule.teams[x], value: workflowModule.teams[x] });
	   }

      return structuredTeams;
    }

    get defaultPages() {
	   return [
        'Tasq list tasq view',
        'Tasq list well view',
        'Tasq list pad view',
        'Tasq kanban view',
      ];
    }

    get_plotly_html() {
      console.log('IDENTITY_POOL_ID::: ');
      console.log(IDENTITY_POOL_ID);
      AWS.config.update({
        region: IDENTITY_POOL_REGION,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: IDENTITY_POOL_ID,
        }),
      });

      const s3 = new AWS.S3({
        region: POST_SHAPEFILE_LAMBDA_REGION,
        apiVersion: '2015-03-31',
      });

      s3.getObject(
        { Bucket: 'temp-plots', Key: 'cognito/dev-swn_tasq_frontend_userpool/hello.html' },
        (error, data) => {
          if (error != null) {
            alert(`Failed to retrieve an object: ${error}`);
          } else {
            alert(`Loaded ${data.ContentLength} bytes`);
            // do something with data.Body
          }
        },
      );
    }

    async created() {
		// this.get_plotly_html()
		// this.get_lambda_shapefile_names()
      // this.dataLoading = true;
      this.getRowNodeId = (data) => data.email;
      await workflowModule.listTeams({ useExisting: true });
      await workflowModule.getUserProfile({ username: accountModule.user.email.toLowerCase() });
      //  console.log(workflowModule.user)
      if (workflowModule.user.role !== 'Engineers' && !workflowModule.user.userAdminAccess) {
        this.$router.push({
          name: 'Home',
        });
      }
    }





	get_lambda_shapefile_names() {

		AWS.config.update({
		region: IDENTITY_POOL_REGION,
		credentials: new AWS.CognitoIdentityCredentials({
			IdentityPoolId: IDENTITY_POOL_ID,
		}),
		});
		const lambda = new AWS.Lambda({
		region: POST_SHAPEFILE_LAMBDA_REGION,
		apiVersion: '2015-03-31',
		});


		// @ts-ignore
		const pullParams = {
		FunctionName: "tasq-workflow-dev-GetFullPlotFileSyncSource",
		InvocationType: 'RequestResponse', // Event | RequestResponse | DryRun
		LogType: 'None',
		Payload: JSON.stringify({
			Operator: "swn",
		}),
		};
		lambda.invoke(pullParams, (err, data) => {
		if (err) {
			console.log(err);
		} else {

			// location.reload();
		}
		});
	}










    get allUsersList() {
      return accountModule.allUsersList;
    }

    formatPhoneNumber(phoneNumberString) {
      const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return phoneNumberString;
    }

    async fetchData() {
      this.rowData = [];
      this.dataLoading = true;
      await accountModule.getReassignmentList({ useExisting: false });
      const usersData = accountModule.allUsersList;
      // console.log(usersData)

      usersData.sort((a, b) => a.name.localeCompare(b.name));
      // usersData = usersData.sort((a, b) => a.name.localeCompare(b.name));
      for (let i = 0; i < usersData.length; i++) {
        const user = usersData[i];
        // eslint-disable-next-line no-await-in-loop
        // let parsePhoneNumber =

        // console.log(userDetails);
        // console.log(userAreaDetails);
        // console.log(user);
        // console.log(userDetails)
        this.rowData.push({

          name: user.name,
          email: user.email,

          phoneNumber: user.phone_number,

          formatNumber: user.phone_number,
          nationalNumber: user.phone_number && user.phone_number.length ? parsePhoneNumber(user.phone_number)?.nationalNumber : '',
          countryCode: user.phone_number && user.phone_number.length  ? parsePhoneNumber(user.phone_number)?.country : 'US',
          username: user.email,

          actions: false,
          active: user.active ? 'Active' : 'InActive',

        });
      }

      usersData.sort((a, b) => b.name.localeCompare(a.name));
      const userDetailList: any = [];

      usersData.forEach(async (user) => {
        const userAreaDetails = await workflowModule.getAreasForUser({ username: user.email.toLowerCase() });
        const userDetails: any = await workflowModule.getUserDetails({ username: user.email.toLowerCase() });
        this.rowData.filter((d) => d.row !== user.name);
        userDetailList.push({
        // console.log(user);
          name: user.name,
          email: user.email,

          phoneNumber: user.phone_number,

          nationalNumber: parsePhoneNumber(user.phone_number)?.nationalNumber,
          countryCode: parsePhoneNumber(user.phone_number)?.country,

          formatNumber: this.formatPhoneNumber(user.phone_number),

          username: user.email,

          actions: false,
          active: user.active ? 'Active' : 'InActive',
          routes: userDetails.routes && JSON.parse(userDetails.routes) ? JSON.parse(userDetails.routes) : '',
          id: userDetails.user_id,
          defaultUserFilter: userDetails.default_user_filter || '',
          // eslint-disable-next-line no-unused-expressions
          role: userDetails.role,
          // eslint-disable-next-line no-unused-expressions
          userAdminAccess: userDetails.user_admin_access || userDetails.role === 'Engineers' ? 'Enable' : 'Disable',
          // eslint-disable-next-line no-unused-expressions
          // eslint-disable-next-line no-param-reassign
          // eslint-disable-next-line no-unused-expressions
          team: userDetails.team && userDetails.team.includes('Team_') ? userDetails.team.replace('Team_', '').replace('"', '').replace('"', '') : userDetails.team,
          adminChartAccess: userDetails.admin_chart_access ? 'Enable' : 'Disable',
          defaultPage: userDetails.default_page ? userDetails.default_page : this.getDefaultPage(userDetails.role),
          firstName: userDetails.first_name,
          lastName: userDetails.last_name,
          area: userAreaDetails || '',
        });
        // return  user;
      });

      console.log(userDetailList);
      // userDetailList = [...new Set(userDetailList.map(item => item.group))];
      this.dataLoading = false;
      await this.sleep(9500);
      this.rowData = userDetailList;

      // console.log(this.rowData);
      // this.availableWellNames = assetsModule.enabledWells.map((w) => w.wellName);

      this.rowData.sort((a, b) => a.name.localeCompare(b.name));
      this.gridApi.refreshCells();
    }

    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });
    }

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    }

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async addRow() {
      if (this.newWellName) {
        this.rowData.push({ Well: this.newWellName });
        this.newWellName = '';
      }
      await this.saveGroupDetailsToBackend();
    }

    onResize() {
      this.windowWidth = window.innerWidth;
    }

    @Watch('columnDefs')
    updateRowData(data) {
      if (data) {
        if (data.length > 1 && data[1].field === 'Add Column +' && data[1].pinned === 'left') {
          this.columnDefs[1].pinned = 'right';
        }
      }
    }
}

