<template>
  <div :id="tagID">
    <span
      v-if="isLoadingComplete"
      ref="buttonActionMenu"
      style="display: inline-block;  vertical-align: middle; line-height: normal;"
      class="material-icons cursor-pointer"
      @click="openActionMenu"
    >
      more_vert
    </span>
    <div
      v-else
      style="width: calc(50% - 25px); margin-right: 15px; margin-top: 20px; height: 38px;"
      type="submit"
      class="rounded text-white text-sm text-center float-right font-md px-9 py-2 cursor-pointer"
    >
      <div
        style=""
        class="loader"
      >
        Loading...
      </div>
    </div>

    <div
      v-show="showMenuModal"
      ref="buttonActionDialog"
      v-closable="{
        exclude: ['buttonActionMenu'],
        handler: 'closeActionMenu'
      }"
      class="fixed z-50 w-40 -mt-6  mr-2 px-2 space-y-1 transform bg-white rounded-md right-0 translate-x-1
      min-w-max bg-charcoal shadow-lg"
    >
      <ul class="grid my-2">
        <li @click="editUserRecord()">
          <a
            class="px-3 py-2 cursor-pointer rounded-md block text-base transition border-l-4
            hover:border-cyan hover:bg-gray-500 border-transparent"
          >
            Edit
          </a>
        </li>
        <li
          v-if="data.active === 'Active'"
          @click="deleteUserRecord"
        >
          <a
            class="px-3 py-2 cursor-pointer rounded-md block text-base transition border-l-4
            hover:border-cyan hover:bg-gray-500 border-transparent"
          >
            Disable
          </a>
        </li>
        <li
          v-else
          @click="enableUserRecord"
        >
          <a
            class="px-3 py-2 cursor-pointer rounded-md block text-base transition border-l-4
          hover:border-cyan hover:bg-gray-500 border-transparent"
          >
            Enable
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

export default {

  data() {
    return {
      showBtn: false,
      width: window.innerWidth,
      showMenuModal: false,
      isLoadingComplete: false,
      tagId: '',
      data: {},
    };
  },

  isPopup() {
    return true;
  },

  beforeDestroy() {
    const documentObj = document.querySelector('#ag-grid-id > div:nth-child(3) > div > div > div.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed > div.ag-root.ag-unselectable.ag-layout-normal > div.ag-body-viewport.ag-layout-normal.ag-row-no-animation');
    if (documentObj) {
      documentObj.removeEventListener(
        'scroll',
        () => this.runOnScroll(),
      );
    }
  },
  created() {
    if (this.width < 700) {
      this.showBtn = true;
    }
    // console.log(this.params)
    const objectID = this.params.data.email;
    this.tagID = `${objectID}Tag`;
    const gridApi = this.params.api;
    this.data = gridApi.getRowNode(objectID).data;
    const documentObj = document.querySelector('#ag-grid-id > div:nth-child(3) > div > div > div.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed > div.ag-root.ag-unselectable.ag-layout-normal > div.ag-body-viewport.ag-layout-normal.ag-row-no-animation');
    documentObj.addEventListener(
      'scroll',
      () => this.runOnScroll(),
      { passive: true },
    );


      if (Object.keys(this.params.data).includes('team')) {
        this.isLoadingComplete = true;
      }

  },

  methods: {

    runOnScroll() {
      this.showMenuModal = false;
    },
    editUserRecord() {
      const objectID = this.params.data.email;
      const gridApi = this.params.api;
      const { data } = gridApi.getRowNode(objectID);
      this.params.context.componentParent.editUserRecord(data);
      this.showMenuModal = false;

    //   this.params.api.updateRowData({ remove: [rowNode ] });
    },

    deleteUserRecord() {
      const objectID = this.params.data.email;
      const gridApi = this.params.api;
      const { data } = gridApi.getRowNode(objectID);
      this.params.context.componentParent.deleteUserRecord(data);
      this.showMenuModal = false;
    },

    enableUserRecord() {
      const objectID = this.params.data.email;
      const gridApi = this.params.api;
      const { data } = gridApi.getRowNode(objectID);
      this.params.context.componentParent.enableUserRecord(data);
      this.showMenuModal = false;
    },

    openActionMenu() {
      this.showMenuModal = !this.showMenuModal;
      // console.log(this.$refs)
      const element = this.$refs.buttonActionMenu;
      // console.log(element)

      // console.log(element)
      // ms.$refs.list.style.width = `${this.$el.clientWidth}px`;

      // ms.$refs.list.style.position = 'fixed';
      // let element = document.querySelector(`${this.tagID} > .multiselect__content-wrapper`)
      // let parent = document.getElementById(this.tagID)
      // console.log(element)
      if (element) {
        const dropdown = this.$refs.buttonActionDialog;
        // console.log(dropdown)
        const cor = element.getBoundingClientRect();
        const { top } = cor;
        if (top > 50) {
          dropdown.style.top = `${top}px`;
        }
      }
    },
    closeActionMenu() {
      // console.log('close')
      this.showMenuModal = false;
    },

  },
};
</script>

<style scoped>

.loader,
.loader:after {
    border-radius: 50%;
    width: 12em;
    height: 12em;
}

.loader {
    margin: 0px auto;
    margin-left: 1px;
    margin-right: 1px;
    font-size: 2px;
    position: relative;
    text-indent: -9999em;
    border-top: 2em solid rgba(255, 255, 255, 0.2);
    border-right: 2em solid rgba(255, 255, 255, 0.2);
    border-bottom: 2em solid rgba(255, 255, 255, 0.2);
    border-left: 2em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 0.7s infinite linear;
    animation: load8 0.7s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

</style>
